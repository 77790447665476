<template>
	<div  v-if="documentLoaded">
		<div class="d-flex justify-space-between align-center mb-2 mt-6">
			<div class="input-wrap">
				<h5 class="mb-2">Generar Nota ({{ document.series }}-{{ document.number }})</h5>
			</div>
			<div class="action-btn-wrap">

			</div>
		</div>

		<v-card color="secondary" class="pa-3">
			<form autocomplete="off" @submit.prevent="submit">
				<v-row dense>
					<v-col cols="12" xl="2" lg="2" md="2" sm="6">
						<div
							class="form-group"
							:class="{ 'has-danger': errors.document_type_id }"
						>
							<label class="control-label">Tipo comprobante</label>
							<el-select
								v-model="form.document_type_id"
								@change="changeDocumentType"
							>
								<el-option
									v-for="option in document_types"
									:key="option.id"
									:value="option.id"
									:label="option.description"
								></el-option>
							</el-select>
							<small
								class="form-control-feedback"
								v-if="errors.document_type_id"
								v-text="errors.document_type_id[0]"
							></small>
						</div>
					</v-col>
					<v-col cols="12" xl="2" lg="2" md="2" sm="6">
						<div class="form-group" :class="{ 'has-danger': errors.series_id }">
							<label class="control-label">Serie</label>
							<el-select v-model="form.series_id">
								<el-option
									v-for="option in series"
									:key="option.id"
									:value="option.id"
									:label="option.number"
								></el-option>
							</el-select>
							<small
								class="form-control-feedback"
								v-if="errors.series_id"
								v-text="errors.series_id[0]"
							></small>
						</div>
					</v-col>
					<v-col cols="12" xl="2" lg="2" md="2" sm="6">
						<template v-if="form.document_type_id === '08'">
							<div
								class="form-group"
								:class="{ 'has-danger': errors['note.note_debit_type_id'] }"
							>
								<label class="control-label">Tipo nota de débito</label>
								<el-select v-model="form.note_credit_or_debit_type_id">
									<el-option
										v-for="option in note_debit_types"
										:key="option.id"
										:value="option.id"
										:label="option.description"
									></el-option>
								</el-select>
								<small
									class="form-control-feedback"
									v-if="errors['note.note_debit_type_id']"
									v-text="errors['note.note_debit_type_id'][0]"
								></small>
							</div>
						</template>
						<template v-else>
							<div
								class="form-group"
								:class="{
									'has-danger': errors['note.note_credit_type_id'],
								}"
							>
								<label class="control-label">Tipo nota de crédito</label>
								<el-select v-model="form.note_credit_or_debit_type_id">
									<el-option
										v-for="option in note_credit_types"
										:key="option.id"
										:value="option.id"
										:label="option.description"
									></el-option>
								</el-select>
								<small
									class="form-control-feedback"
									v-if="errors['note.note_credit_type_id']"
									v-text="errors['note.note_credit_type_id'][0]"
								></small>
							</div>
						</template>
					</v-col>
					<v-col cols="12" xl="4" lg="4" md="4" sm="12">
						<div
							class="form-group"
							:class="{ 'has-danger': errors['note.note_description'] }"
						>
							<label class="control-label">Descripción</label>
							<el-input v-model="form.note_description"></el-input>
							<small
								class="form-control-feedback"
								v-if="errors['note.note_description']"
								v-text="errors['note.note_description'][0]"
							></small>
						</div>
					</v-col>
					<v-col cols="12" xl="2" lg="2" md="2" sm="6">
						<div
							class="form-group"
							:class="{ 'has-danger': errors.date_of_issue }"
						>
							<label class="control-label">Fec. Emisión</label>
							<el-date-picker
								v-model="form.date_of_issue"
								type="date"
								value-format="yyyy-MM-dd"
								format="dd/mm/yyyy"
								:clearable="false"
								@change="changeDateOfIssue()"
								readonly
							></el-date-picker>
							<small
								class="form-control-feedback"
								v-if="errors.date_of_issue"
								v-text="errors.date_of_issue[0]"
							></small>
						</div>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="12" xl="6" lg="6" md="6" sm="6">
						<div
							class="form-group"
							:class="{ 'has-danger': errors.customer_id }"
						>
							<label class="control-label">Cliente</label>
							<el-select
								v-model="form.customer_id"
								filterable
								:disabled="true"
							>
								<el-option
									v-for="option in customers"
									:key="option.id"
									:value="option.id"
									:label="option.description"
								></el-option>
							</el-select>
							<small
								class="form-control-feedback"
								v-if="errors.customer_id"
								v-text="errors.customer_id[0]"
							></small>
						</div>
					</v-col>
					<v-col cols="12" xl="3" lg="3" md="3" sm="6">
						<div
							class="form-group"
							:class="{ 'has-danger': errors.currency_type_id }"
						>
							<label class="control-label">Moneda</label>
							<el-select v-model="form.currency_type_id" :disabled="true">
								<el-option
									v-for="option in currency_types"
									:key="option.id"
									:value="option.id"
									:label="option.description"
								></el-option>
							</el-select>
							<small
								class="form-control-feedback"
								v-if="errors.currency_type_id"
								v-text="errors.currency_type_id[0]"
							></small>
						</div>
					</v-col>
					<v-col cols="12" xl="3" lg="3" md="3" sm="6">
						<div
							class="form-group"
							:class="{ 'has-danger': errors.purchase_order }"
						>
							<label class="control-label">Orden Compra</label>
							<el-input v-model="form.purchase_order"></el-input>
							<small
								class="form-control-feedback"
								v-if="errors.purchase_order"
								v-text="errors.purchase_order[0]"
							></small>
						</div>
					</v-col>
				</v-row>

				<v-row dense>
					<v-col cols="12" xl="12">
						<v-simple-table dense>
							<template v-slot:default>
								<thead>
									<tr>
										<th width="3%" class="text-right">N°</th>
										<th width="10%" class="text-right">Cantidad</th>
										<th width="30%">Producto</th>
										<th class="text-right">Precio Unit.</th>
										<th class="text-right">Total</th>
										<th class="text-right">Opciones</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(row, index) in form.items" :key="index">
										<td class="text-right align-middle">
											{{ index + 1 }}
										</td>
										<td class="text-right align-middle">
											<el-input
												class="input-text-right"
												v-model="row.quantity"
												:min="0"
												:step="1"
												readonly
											></el-input>
										</td>
										<td class="align-middle">
											{{ row.item.name }}
										</td>
										<td class="text-right align-middle">
											<el-input
												v-model="row.unit_price"
												class="input-text-right"
												readonly
											></el-input>
										</td>
										<td class="text-right align-middle">
											<el-input
												v-model="row.total"
												class="input-text-right"
												readonly
											></el-input>
										</td>
										<td class="text-right align-middle">

											<el-button
												type="danger"
												icon="el-icon-delete"
												@click.prevent="clickRemoveItem(index)"
											></el-button>
										</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
						<v-row dense class="justify-content-end" v-if="form.total > 0">
							<v-col cols="12" xl="6" lg="6" offset-xl="6" offset-lg="6">
								<v-simple-table dense>
									<template v-slot:default>
										<tbody>

											<tr v-if="form.total_exportation > 0">
												<td colspan="2" class="text-right">
													Op. Exportación:
												</td>
												<td class="text-right">
													{{ currency_type.symbol }}
													{{ form.total_exportation }}
												</td>
											</tr>
											<tr v-if="form.total_free > 0">
												<td colspan="2" class="text-right">
													Op. Gratuitas:
												</td>
												<td class="text-right">
													{{ currency_type.symbol }} {{ form.total_free }}
												</td>
											</tr>
											<tr v-if="form.total_unaffected > 0">
												<td colspan="2" class="text-right">
													Op. Inafectas:
												</td>
												<td class="text-right">
													{{ currency_type.symbol }}
													{{ form.total_unaffected }}
												</td>
											</tr>
											<tr v-if="form.total_exonerated > 0">
												<td colspan="2" class="text-right">
													Op. Exoneradas:
												</td>
												<td class="text-right">
													{{ currency_type.symbol }}
													{{ form.total_exonerated }}
												</td>
											</tr>
											<tr v-if="form.total_taxed > 0">
												<td colspan="2" class="text-right">Op. Gravada:</td>
												<td class="text-right">
													{{ currency_type.symbol }}
													{{ form.total_taxed }}
												</td>
											</tr>
											<tr v-if="form.total_igv > 0">
												<td colspan="2" class="text-right">IGV:</td>
												<td class="text-right">
													{{ currency_type.symbol }} {{ form.total_igv }}
												</td>
											</tr>
											<tr class="h6" v-if="form.total > 0">
												<td colspan="2" class="text-right">Total:</td>
												<td class="text-right">
													<strong
														>{{ currency_type.symbol }}
														{{ form.total }}</strong
													>
												</td>
											</tr>
										</tbody>
									</template>
								</v-simple-table>
							</v-col>
						</v-row>
					</v-col>
				</v-row>



				<div class="form-actions text-right mt-4">
					<el-button type="danger" @click.prevent="close()">Cancelar</el-button>
					<el-button
						type="primary"
						native-type="submit"
						:loading="loading_submit"
						v-if="form.items.length > 0 && form.total > 0"
					>
						<template v-if="loading_submit">
							Generando...
						</template>
						<template v-else>
							Generar
						</template>
					</el-button>
				</div>
			</form>
		</v-card>


		<document-options
			:showDialog.sync="showDialogOptions"
			:recordId="documentNewId"
			:isGenerate="true"
		></document-options>
	</div>
</template>

<script>
import DocumentOptions from './Component/Options';
import { functions, exchangeRate } from 'Mixins/functions';
import { calculateRowItem } from 'Helpers/functions';
import { confirm } from 'Mixins/confirm';
import filter from 'lodash/filter';
import round from 'lodash/round';
import find from 'lodash/find';
import dayjs from 'dayjs';

export default {
	components: { DocumentOptions },
	mixins: [functions, exchangeRate, confirm],
	props: ['document_affected'],
	data() {
		return {
			showDialogOptions: false,
			loading_search_items: false,
			loading_submit: false,
			resource: 'documents',
			errors: {},
			form: {},
			form_item: {},
			document_types: [],
			currency_types: [],
			customers: [],
			all_series: [],
			series: [],
			currency_type: {},
			documentNewId: null,
			note_credit_types: [],
			note_debit_types: [],
			user: {},
			document: {},
			operation_types: [],
			is_contingency: false,
			items: [],
			item_unit_type: {},
			all_affectation_igv_types: [],
			affectation_igv_types: [],
			row: {},
			searchByBarCode: false,
			input_item: null,
			total_global_discount: 0,
			is_amount: true,
			documentLoaded: false,
		};
	},
	async created() {
		await this.getDocument()
		await this.getDynamicTables();
		await this.getStaticTables();
		// this.document = this.document_affected;

		this.getCustomer();
	},
	mounted() {
		window.addEventListener(
			'keypress',
			function(e) {
				if (e.which == 43) {
					this.clickAddItem();
				}
			}.bind(this)
		);
	},
	methods: {
		async getDocument() {
			await this.$http.get(`/${this.resource}/note/${this.$route.params.id}`).then((resp) => {
				this.document = resp.data;
				this.documentLoaded = true;

				this.initForm();
				this.initFormItem();
			});
		},
		initForm() {
			console.log(this.document.establishment_id)
			this.errors = {};
			this.form = {
				establishment_id: this.document.establishment_id,
				warehouse_id: this.document.warehouse_id,
				document_type_id: null,
				series_id: null,
				number: '#',
				date_of_issue: dayjs().format('YYYY-MM-DD'),
				time_of_issue: dayjs().format('HH:mm:ss'),
				customer_id: this.document.customer_id,
				currency_type_id: this.document.currency_type_id,
				purchase_order: null,
				exchange_rate_sale: 1,
				payment_condition_type_id: '1',
				total_prepayment: this.document.total_prepayment,
				total_charge: this.document.total_charge,
				total_discount: this.document.total_discount,
				total_exportation: this.document.total_exportation,
				total_free: this.document.total_free,
				total_taxed: this.document.total_taxed,
				total_unaffected: this.document.total_unaffected,
				total_exonerated: this.document.total_exonerated,
				total_igv: this.document.total_igv,
				total_base_isc: this.document.total_base_isc,
				total_isc: this.document.total_isc,
				total_base_other_taxes: this.document.total_base_other_taxes,
				total_other_taxes: this.document.total_other_taxes,
				total_plastic_bag_taxes: this.document.total_plastic_bag_taxes,
				total_taxes: this.document.total_taxes,
				total_value: this.document.total_value,
				total: this.document.total,
				items: this.document.items,
				discount: {
					discount_type_id: '02',
					description: 'Descuento Global afecta a la base imponible',
					factor: 0,
					amount: 0,
					base: 0,
				},
				affected_document_id: this.document.id,
				note_credit_or_debit_type_id: null,
				note_description: null,
				actions: {
					format_pdf: 'ticket',
				},
				operation_type_id: null,
			};
		},
		initFormItem() {
			this.form_item = {
				item_id: null,
				item: {},
				item_unit_type_id: null,
				affectation_igv_type_id: null,
				affectation_igv_type: {},
				has_isc: false,
				system_isc_type_id: null,
				percentage_isc: 0,
				suggested_price: 0,
				quantity: 1,
				unit_price_value: 0,
				static_price: 0,
				unit_price: 0,
				charges: [],
				discount: {
					discount_type_id: '02',
					discount_type: { base: true },
					description: 'Descuento que afecta la base imponible del IGV',
					percentage: 0,
					factor: 0,
					amount: 0,
					base: 0,
					is_amount: true,
				},
				attributes: [],
				has_igv: null,
				item_unit_types: [],
				has_plastic_bag_taxes: false,
			};

			this.item_unit_type = {};
			this.items = [];
		},
		async getStaticTables() {
			await this.$http.get(`/${this.resource}/static-tables`).then((resp) => {
				this.document_types = resp.data.document_types_note;
				this.currency_types = resp.data.currency_types;
				this.note_credit_types = resp.data.note_credit_types;
				this.note_debit_types = resp.data.note_debit_types;
				this.operation_types = resp.data.operation_types;
				this.all_affectation_igv_types = resp.data.affectation_igv_types;

				this.currency_type = find(this.currency_types, { id: this.form.currency_type_id });
				console.log(this.currency_type)
				this.form.document_type_id =
					this.document_types.length > 0 ? this.document_types[0].id : null;
				this.form.operation_type_id =
					this.operation_types.length > 0 ? this.operation_types[0].id : null;

				let operation_type = find(this.operation_types, {
					id: this.form.operation_type_id,
				});
				this.affectation_igv_types = filter(this.all_affectation_igv_types, {
					exportation: operation_type.exportation,
				});
				this.changeDocumentType();
			});
		},
		async getDynamicTables() {
			await this.$http.get(`/${this.resource}/dynamic-tables`).then((response) => {
				this.all_series = response.data.series;
				this.user = response.data.user;
				this.document_type_03_filter = response.data.document_type_03_filter;
				this.configuration = response.data.configuration;

				// this.changeDateOfIssue();
			});
		},
		async resetForm() {
			await this.getNote();
			await this.initForm();
			this.form.operation_type_id =
				this.operation_types.length > 0 ? this.operation_types[0].id : null;
			this.form.document_type_id =
				this.document_types.length > 0 ? this.document_types[0].id : null;
			this.changeDocumentType();
			// this.changeDateOfIssue();
		},
		getNote() {
			this.$http
				.get(`/${this.resource}/note/record/${this.form.affected_document_id}`)
				.then((response) => {
					this.document = response.data;
				});
		},
		changeDocumentType() {
			this.form.note_credit_or_debit_type_id = null;
			this.form.series_id = null;
			// if(this.is_contingency) {
			//     this.series = filter(this.all_series, {'document_type_id': this.form.document_type_id,
			//                                              'contingency': this.is_contingency});
			// } else {
			let document_type = find(this.document_types, { id: this.form.document_type_id });
			let firstChar = this.document.group_id === '01' ? 'F' : 'B';
			this.series = filter(this.all_series, (s) => {
				return (
					s.document_type_id === document_type.id && s.number.substr(0, 1) === firstChar
				);
			});
			// }

			this.form.series_id = this.series.length > 0 ? this.series[0].id : null;
		},
		changeDateOfIssue() {
			this.searchExchangeRateByDate(this.form.date_of_issue).then((response) => {
				this.form.exchange_rate_sale = response;
			});
		},
		clickAddItem() {
			if (!this.form_item.item_id) {
				return this.$message.error('Error! selecciona un producto para continuar.');
			}

			if (!Number(this.form_item.quantity)) {
				return this.$message.error('Error! La cantidad ingresada es incorrrecto.');
			}

			if (!Number(this.form_item.unit_price) || this.form_item.unit_price <= 0) {
				return this.$message.error('Error! el precio unitario ingresado es incorrrecto.');
			}

			this.row = calculateRowItem(
				this.form_item,
				this.form.currency_type_id,
				this.form.exchange_rate_sale
			);

			this.form.items.push(JSON.parse(JSON.stringify(this.row)));

			this.initFormItem();
			this.$refs.quantity.$el.getElementsByTagName('input')[0].focus();

			this.calculateTotal();
		},
		clickRemoveItem(index) {
			this.form.items.splice(index, 1);
			this.calculateTotal();
		},
		changeItem() {
			this.form_item.item = find(this.items, { id: this.form_item.item_id });
			this.form_item.affectation_igv_type_id = this.form_item.item.sale_affectation_igv_type_id;
			this.form_item.affectation_igv_type = find(this.affectation_igv_types, {
				id: this.form_item.affectation_igv_type_id,
			});

			this.form_item.unit_price_value = this.form_item.item.sale_unit_price;

			let unit_price = this.form_item.item.has_igv
				? this.form_item.unit_price_value
				: this.form_item.unit_price_value * 1.10;

			this.form_item.unit_price = unit_price;
			this.form_item.static_price = unit_price;
			this.form_item.unit_price_real = unit_price;
			this.form_item.item.unit_price = unit_price;
		},
		changeUnitPriceValue() {
			let unit_price = this.form_item.item.has_igv
				? this.form_item.unit_price_value
				: this.form_item.unit_price_value * 1.10;

			this.form_item.unit_price = unit_price;
			this.form_item.static_price = unit_price;
			this.form_item.unit_price_real = unit_price;
			this.form_item.item.unit_price = unit_price;
		},
		searchRemoteItems(input) {
			if (input != '') {
				this.loading_search_items = true;
				let parameters = `input=${input}`;
				this.$http.get(`/${this.resource}/search/items?${parameters}`).then((response) => {
					this.items = response.data;
					this.loading_search_items = false;
				});
			} else {
				this.items = [];
			}
		},
		async searchItemByBarCode() {
			if (this.input_item) {
				let parameters = `input=${this.input_item}`;
				await this.$http
					.get(`/${this.resource}/search/item/bar-code?${parameters}`)
					.then((response) => {
						if (response.data.success) {
							this.form_item.item_id = response.data.data.id;
							this.items.push(response.data.data);
							this.changeItem();
							// this.clickAddItem(response.data.data)
							this.$message.success(response.data.message);
						} else {
							this.$message.error(response.data.message);
						}

						this.input_item = null;
					});
			} else {
				this.create();
			}
		},
		addRow(row) {
			this.form.items.push(row);
			this.calculateTotal();
		},
		changeRow(index, change_igv_type = false, input = false) {
			this.form.items[index].affectation_igv_type = find(this.affectation_igv_types, {
				id: this.form.items[index].affectation_igv_type_id,
			});

			if (change_igv_type) {
				this.form.items[index].unit_price_value = this.form.items[index].static_price;
			} else {
				this.form.items[index].unit_price_value = this.form.items[index].unit_price;
			}

			let unit_price = this.form.items[index].item.has_igv
				? this.form.items[index].unit_price_value
				: this.form.items[index].unit_price_value * 1.10;

			this.form.items[index].item.unit_price = unit_price;

			this.calculateRowItem(index, change_igv_type, input);
		},
		calculateRowItem(index, change_igv_type, input) {
			let currency_type_id_new = this.form.currency_type_id;
			let exchange_rate_sale = this.form.exchange_rate_sale;
			let row = this.form.items[index];
			let currency_type_id_old = row.item.currency_type_id;
			let unit_price = parseFloat(row.item.unit_price);

			if (currency_type_id_old === 'PEN' && currency_type_id_old !== currency_type_id_new) {
				unit_price = unit_price / exchange_rate_sale;
			}

			if (currency_type_id_new === 'PEN' && currency_type_id_old !== currency_type_id_new) {
				unit_price = unit_price * exchange_rate_sale;
			}

			console.log(unit_price)

			let percentage_igv = 10;
			row.static_price = input ? row.unit_price : row.static_price;
			row.unit_price = change_igv_type ? row.static_price : row.unit_price;
			let unit_value = row.unit_price;

			if (row.item_id !== null) {
				if (row.affectation_igv_type.free) {
					unit_value = parseFloat(row.unit_price / (1 + percentage_igv / 100) / 1.10);
				}
			}

			if (row.affectation_igv_type_id === '10') {
				unit_value = row.unit_price / (1 + percentage_igv / 100);
			}

			row.unit_value = unit_value;

			let total_value_partial = unit_value * row.quantity;

			/* Discounts */
			let discount_base = 0;
			let discount_no_base = 0;
			let discount = row.discount;
			if (discount.percentage > 0) {
				if (discount.is_amount) {
					discount.base = total_value_partial;
					discount.amount = discount.percentage / 1.10;

					let percentage =
						100 * (parseFloat(discount.amount) / parseFloat(discount.base));
					discount.factor = percentage / 100;
					if (discount.discount_type.base) {
						discount_base += discount.amount;
					} else {
						discount_no_base += discount.amount;
					}
				} else {
					let percentage = parseFloat(discount.percentage);
					discount.factor = percentage / 100;
					discount.base = total_value_partial;
					discount.amount = discount.base * discount.factor;
					if (discount.discount_type.base) {
						discount_base += discount.amount;
					} else {
						discount_no_base += discount.amount;
					}
				}
			}

			/* Charges */
			let charge_base = 0;
			let charge_no_base = 0;
			// row.charges.forEach((charge, ind) => {
			//     charge.percentage = parseFloat(charge.percentage)
			//     charge.factor = charge.percentage / 100
			//     charge.base = round(total_value_partial, 2)
			//     charge.amount = round(charge.base * charge.factor, 2)
			//     if (charge.charge_type.base) {
			//         charge_base += charge.amount
			//     } else {
			//         charge_no_base += charge.amount
			//     }
			//     row.charges.splice(ind, charge)
			// })

			let total_isc = 0;
			let total_other_taxes = 0;

			let total_discount = discount_base + discount_no_base;
			let total_charge = charge_base + charge_no_base;
			let total_value = total_value_partial - total_discount + total_charge;
			let total_base_igv = total_value_partial - discount_base + total_isc;

			let total_igv = 0;

			if (row.affectation_igv_type_id === '10') {
				total_igv = (total_base_igv * percentage_igv) / 100;
			}
			if (row.affectation_igv_type_id === '20') {
				//Exonerated
				total_igv = 0;
			}
			if (row.affectation_igv_type_id === '30') {
				//Unaffected
				total_igv = 0;
			}

			let total_taxes = total_igv + total_isc + total_other_taxes;
			let total = total_value + total_taxes;

			row.total_charge = total_charge;
			row.total_discount = total_discount;
			row.total_charge = total_charge;
			row.total_value = total_value;
			row.total_base_igv = total_base_igv;
			row.total_igv = total_igv;
			row.total_taxes = total_taxes;
			row.total = round(total, 2);

			if (row.item_id !== null) {
				if (row.affectation_igv_type.free) {
					row.price_type_id = '02';
					row.unit_price = parseFloat(total_value_partial * 1.10);
					row.total_value = parseFloat(
						total_value_partial - total_discount + total_charge
					);
					row.total_igv = parseFloat(row.total_value * 0.10);
					row.total = parseFloat(row.total_value + row.total_igv);
				} else {
					row.price_type_id = '01';
				}
			}

			//impuesto bolsa
			if (row.has_plastic_bag_taxes) {
				row.total_plastic_bag_taxes = round(
					row.quantity * row.item.amount_plastic_bag_taxes,
					1
				);
			}

			this.calculateTotal();
		},
		calculateTotal() {
			let total_discount_item = 0;
			// let total_discount = 0;
			let total_charge = 0;
			let total_exportation = 0;
			let total_taxed = 0;
			let total_exonerated = 0;
			let total_unaffected = 0;
			let total_free = 0;
			let total_igv = 0;
			let total_value = 0;
			let total = 0;
			this.form.items.forEach((row) => {
				total_discount_item += parseFloat(row.total_discount);
				total_charge += parseFloat(row.total_charge);

				if (row.affectation_igv_type_id === '10') {
					total_taxed += parseFloat(row.total_value);
				}
				if (row.affectation_igv_type_id === '20') {
					total_exonerated += parseFloat(row.total_value);
				}
				if (row.affectation_igv_type_id === '30') {
					total_unaffected += parseFloat(row.total_value);
				}
				if (row.affectation_igv_type_id === '40') {
					total_exportation += parseFloat(row.total_value);
				}
				if (['10', '20', '30', '40'].indexOf(row.affectation_igv_type_id) < 0) {
					total_free += parseFloat(row.total_value);
				}

				total_value += parseFloat(row.total_value);
				total_igv += parseFloat(row.total_igv);
				total += parseFloat(row.total);
			});

			let amount = 0;
			let factor = 0;
			if (this.total_global_discount > 0) {
				if (this.is_amount) {
					amount = parseFloat(this.total_global_discount / 1.10);
					factor = parseFloat(amount / total_taxed);
				} else {
					amount = parseFloat(total_taxed * (this.total_global_discount / 100));
					factor = parseFloat(this.total_global_discount / 100);
				}

				this.form.discount.base = round(total_taxed, 2);
				this.form.discount.amount = round(amount, 2);
				this.form.discount.factor = round(factor, 5);

				total_value = parseFloat(total_taxed - amount);
				total_igv = parseFloat(total_value * 0.10);
				total_taxed = parseFloat(total_value);
				total = parseFloat(total_value + total_igv);
			}

			this.form.total_discount_item = round(total_discount_item, 2);
			this.form.total_discount_global = round(amount, 2);
			this.form.total_discount = round(total_discount_item + amount, 2);
			this.form.total_exportation = round(total_exportation, 2);
			this.form.total_taxed = round(total_taxed, 2);
			this.form.total_exonerated = round(total_exonerated, 2);
			this.form.total_unaffected = round(total_unaffected, 2);
			this.form.total_charge = round(total_charge, 2);
			this.form.total_free = round(total_free, 2);
			this.form.total_igv = round(total_igv, 2);
			this.form.total_value = round(total_value, 2);
			this.form.total_taxes = round(total_igv, 2);
			this.form.total = round(total, 2);
		},
		submit() {
			this.confirm().then(() => {
				this.loading_submit = true;
				this.$http
					.post(`/${this.resource}`, this.form)
					.then((response) => {
						if (response.data.success) {
							this.resetForm();
							this.documentNewId = response.data.data.id;
							this.showDialogOptions = true;
						} else {
							this.$message.error(response.data.message);
						}
					})
					.catch((error) => {
						if (error.response.status === 422) {
							this.errors = error.response.data.errors;
						} else {
							this.$message.error(error.response.data.message);
						}
					})
					.then(() => {
						this.loading_submit = false;
					});
			});
		},
		getCustomer() {
			this.$http
				.get(`/${this.resource}/search/customer/${this.document.customer_id}`)
				.then((response) => {
					this.customers = response.data;
					this.form.customer_id = this.document.customer_id;
				});
		},
		close() {
			this.$router.push({ name: 'DocumentIndex'});
			// location.href = '/documents';
		},
	},
};
</script>
